import { axios, setAuthToken } from ".";

const userUrl = "/users";

async function refreshUser(){
    return axios.get(`/auth/refresh`)
        .then(data => data.data);
}

async function login(email, password) {
    return axios.post("/auth/login",{
        email:email,
        password:password
    }).then(data => data.data.content);
}

async function register(user) {
    return axios.post("/auth/register", user)
        .then(data => data.data.content);
}

async function getHouseholds(userId) {
    const data = await axios.get(`${userUrl}/${userId}/households`);
    setAuthToken(data.data.token);
    return data.data.content;
}

async function findByEmail(email){
    return axios.get(`${userUrl}/byEmail/${email}`)
        .then(data => data.data.content);
}

export {login, register, findByEmail, refreshUser, getHouseholds}