import { useMemo } from "react";
import Button from "react-bootstrap/Button";

import { DetailView } from "../layouts/DetailView";
import { InlineField } from "../layouts/InlineField";
import { NameInput } from "../form/NameInput";

export function HouseholdDetailsEdit({nameState, updateName=f=>f, onSave=f=>f, onCancel=f=>f}){

    const nameControl = <NameInput placeholder="Enter name" required={true} setName={updateName} {...nameState}/>

    const fields = useMemo(() => <>
        <InlineField title={"Name:"} element={nameControl} />
    </>, [nameState])

    const buttons = useMemo(() => <>
        <Button data-cy="save-button" variant="primary" type="submit">save</Button>
        <Button data-cy="cancel-button" variant="secondary" onClick={onCancel}>cancel</Button>
    </>, [onSave, onCancel])

    return (
        <DetailView 
            fields={fields}
            buttons={buttons}
        />
    )

    return <DetailView />
}