import { ListItem } from "../layouts/ListItem";
import { MemberBadge } from "../user/MemberBadge";

export function InlineHousehold({membership}){

    const badge = <MemberBadge isOwner={membership.isOwner}/>;

    return (
        <ListItem 
            title={membership.household.name}
            rightSide={badge}
        />
    );
}