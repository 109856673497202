import { createContext, useCallback, useEffect, useState, useContext, useMemo } from "react"
import { useSession } from "./UserProvider";
import { createHousehold, addMember, addOwner, deleteHousehold, updateHousehold, getHouseholdSchedules, getHouseholdMembers } from "../api/households";
import { getHouseholds } from "../api/users";

export const HouseholdContext = createContext();

const useHousehold = () => useContext(HouseholdContext); 

export const useFilterState = () => {
    const {memberships, selectedHousehold, updateSelected, getSchedules} = useHousehold();
    return {memberships, selectedHousehold, updateSelected, getSchedules};
}

export const useSelectedHousehold = () => {
    const {selectedHousehold, getSchedules, getMembers} = useHousehold();
    return {selectedHousehold, getSchedules, getMembers};
}

export const useHouseholdCRUD = () => {
    const { createNewHousehold, deleteHouseholdById, updateHouseholdName, addMemberToHousehold, addOwnerToHousehold, refreshHouseholds } = useHousehold();
    return { createNewHousehold, deleteHouseholdById, updateHouseholdName, addMemberToHousehold, addOwnerToHousehold, refreshHouseholds }
}

export const HouseholdProvider = ({children}) => {

    const {userId, ready} = useSession();
    const [filter, setFilter] = useState(Number.parseInt(localStorage.getItem("HOUSEHOLD_FILTER")));
    const [memberships, setMemberships] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedHousehold, setSelected] = useState();

    useEffect(() => {
        if(filter === 0){
            setSelected();
        } else {
            memberships.forEach(m => {
                if(m.household.id === filter){
                    setSelected(m.household);
                }
            });
        }
    }, [memberships, filter])

    const refreshMemberships = useCallback(async () => {
        if(!ready) return;
        setError();
        setLoading(true);
        getHouseholds(userId)
            .then(data => {
                setMemberships(data);
                setLoading(false);
            });
    }, [userId, ready]);

    const getSchedules = useCallback(async () => {
        if(!selectedHousehold){
            let schedules = [];
            await Promise.all(memberships.map(h => {
                return getHouseholdSchedules(h.household.id).then(s => schedules = schedules.concat(s));
            }));

            return schedules;
        }
        return getHouseholdSchedules(selectedHousehold.id);
    }, [selectedHousehold]);

    const createNewHousehold = useCallback(async (name) => {
        return createHousehold(name, userId)
            .then(data => {
                refreshMemberships();
                return data;
            })
            .catch(err => {
                console.log(err);
                return -1;
            });
    }, [userId, refreshMemberships]);

    const addMemberToHousehold = useCallback((householdId, userId) => {
        return addMember(householdId, userId)
            .catch(err => console.log(err));
    }, []);

    const addOwnerToHousehold = useCallback((householdId, userId) => {
        return addOwner(householdId, userId)
            .catch(err => console.log(err));
    }, []);

    const getMembers = useCallback(() => {
        return getHouseholdMembers(selectedHousehold.id)
    }, [selectedHousehold])

    const deleteHouseholdById = useCallback((householdId) => {
        deleteHousehold(householdId)
            .then(() => refreshMemberships());
    }, [refreshMemberships]);

    const updateHouseholdName = useCallback((name) => {
        return updateHousehold(selectedHousehold.id, name)
            .then(data => {
                refreshMemberships();
                return data
            })
            .catch(err => -1);
    }, [selectedHousehold, refreshMemberships]);

    const updateSelected = useCallback((household) => {
        setSelected(household)
        localStorage.setItem("HOUSEHOLD_FILTER", household?household.id:0);
    }, [setSelected]);

    useEffect(() => {
        refreshMemberships();
    }, [refreshMemberships])

    const value = useMemo(() => ({
        memberships, 
        selectedHousehold,
        filter, 
        error, 
        loading, 
        updateSelected,
        refreshMemberships, 
        createNewHousehold, 
        addMemberToHousehold, 
        addOwnerToHousehold,
        getMembers,
        deleteHouseholdById,
        updateHouseholdName,
        getSchedules
    }), [memberships, 
        selectedHousehold,
        filter, 
        error, 
        loading, 
        updateSelected,
        refreshMemberships, 
        createNewHousehold, 
        addMemberToHousehold,
        addOwnerToHousehold,
        getMembers, 
        deleteHouseholdById, 
        updateHouseholdName,
        getSchedules]);

    return (
        <HouseholdContext.Provider value={value}>
            {children}
        </HouseholdContext.Provider>
    )
}