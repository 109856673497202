import { useCallback, useState, createContext, useMemo } from "react";
import { loadRecipes, autoComplete, getRecipeById } from "../api/recipes";

export const RecipeContext = createContext();

const cachedRecipes = [];

const addRecipeToCache = (recipe) => {
    if(cachedRecipes.length > 100){
        cachedRecipes.shift();
    }
    cachedRecipes.push(recipe);
}

export function RecipeProvider({children}){

    const [filters, setFilters] = useState({});
    const [recipes, setRecipes] = useState([]);

    const updateFilters = useCallback((filters) => {
        setFilters(filters);
    }, [setFilters]);

    const searchRecipes = useCallback(()=>{
        loadRecipes(filters)
            .then(data => {
                setRecipes(data.results);
                data.results.forEach(recipe => {
                    addRecipeToCache(recipe);
                })
            });
    }, [filters, setRecipes]);

    const getRecipe = useCallback((id) => {
        const recipe = cachedRecipes.find(r => r.id===Number.parseInt(id))
        if(recipe){
            return new Promise((resolve) => {
                resolve(recipe);
            });
        } else {
            return getRecipeById(id).then(data => {
                addRecipeToCache(data);
                return data;
            });
        }
    }, [])

    const autocompleteNames = useCallback(async (name)=>{
        return autoComplete(name);
    },  []);

    const value = useMemo(() => ({
        recipes,
        updateFilters,
        searchRecipes,
        autocompleteNames,
        getRecipe
    }), [recipes, updateFilters, searchRecipes, autocompleteNames, getRecipe]);

    return (
        <RecipeContext.Provider value={value}>
            {children}
        </RecipeContext.Provider>
    );
}