import { createContext, useCallback, useEffect, useState, useMemo, useContext } from "react"

import { useFilterState } from "./HouseholdProvider";
import { deleteMeal, updateMeal, createMeal } from "../api/meals";
import { getMealsInSchedule } from "../api/schedules";

export const MealContext = createContext();

const useMeals = () => useContext(MealContext); 

export const useSelectedMeals = () => {
    const {meals, selectedMeal, loading, error} = useMeals();
    return {meals, selectedMeal, loading, error};
}

export const MealProvider = ({children}) => {
    const {memberships, selectedHousehold, getSchedules} = useFilterState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [meals, setMeals] = useState([]);
    const [selectedMeal, setSelectedMeal] = useState();

    useEffect(()=>{
        updateMeals();
    }, [selectedHousehold]);

    const getMealsFromHousehold = useCallback(async () => {
        let meals = [];
        
        return getSchedules()
            .then(schedules => {
                return Promise.all(schedules.map(s => {
                    return getMealsInSchedule(s.id).then(m => meals = meals.concat(m));
                }))
            })
            .then(() => {
                setMeals(meals);
            })
            
    }, [setMeals, getSchedules]);

    const updateMeals = useCallback(() => {
        getMealsFromHousehold()
            .then(() => setLoading(false))
            .catch(err => setError(err));
    }, [setLoading, setError, getMealsFromHousehold]);

    const createMealWithFields = useCallback(async (fields)=>{
        return getSchedules(selectedHousehold.id)
            .then(schedules => createMeal(fields, schedules[0].id));
    },[selectedHousehold, getSchedules]);

    const updateMealWithFields = useCallback(async (fields, meal)=>{
        return updateMeal(fields, meal.id);
    },[]);

    const deleteMealById = useCallback(async (mealId) => {
        return deleteMeal(mealId);
    }, []);

    const value = useMemo(() => ({
        meals,
        selectedMeal,
        loading,
        error,
        updateMealWithFields,
        setSelectedMeal,
        deleteMealById,
        updateMeals,
        createMealWithFields
    }), [meals, 
        selectedMeal,
        loading,
        error,
        updateMealWithFields, 
        setSelectedMeal,
        deleteMealById, 
        updateMeals, 
        createMealWithFields]);

    return (
        <MealContext.Provider value={value}>
            {children}
        </MealContext.Provider>
    )
} 