import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const cuisines = [
    "African", "American", "British", "Chinese", 
    "French", "German", "Greek", "Indian", 
    "Japanese", "Korean", "Italian", "Spanish", 
    "Thai", "Middle Eastern"
]

const dishTypes = [
    "all", "main course", "side dish", "dessert", 
    "appetizer", "salad", "bread", "breakfast", 
    "soup", "snack"
]

const sortTypes = [
    "", "popularity", "time", "calories"
]


export function RecipeFilter({setFilters=f=>f, searchMeals=f=>f, autoComplete=async f=>f, onCancel=f=>f}) {

    const [filteredCuisines, setFilteredCuisines] = useState(new Set());
    const [filterType, setFilterType] = useState();
    const [sort, setSort] = useState("random");
    const [ascending, setAscending] = useState(false);
    const [name, setName] = useState("");
    const [names, setNames] = useState([]);

    const switchCuisine = useCallback((cuisine)=>{
        if(filteredCuisines.has(cuisine)){
            filteredCuisines.delete(cuisine);
        } else {
            filteredCuisines.add(cuisine);
        }
        setFilteredCuisines(new Set(filteredCuisines));
    },[setFilteredCuisines, filteredCuisines]);

    const updateSort = useCallback((sort)=>{
        setSort(sort===""?"random":sort);
    },[setSort]);

    useEffect(() => {
        let filters = {
            cuisine:Array.from(filteredCuisines.values()).join(","),
            type:filterType,
            sort:sort,
            sortDirection:ascending?"asc":"desc",
            titleMatch:name
        };

        setFilters(filters);
    }, [filteredCuisines, filterType, sort, ascending, name, setFilters]);

    const getNames = useCallback((name) => {
        autoComplete(name)
            .then(data => setNames(data));
    }, [setNames, autoComplete]);

    const updateName = useCallback((newName)=>{
        setName(newName);
        getNames(newName);
    },[getNames]);

    return (<Container>
        <Row>
            <Form className="recipeFilter">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6>Cuisine</h6></Accordion.Header>
                        <Accordion.Body>
                            <div key={`inline-checkbox`} className="mb-3">
                                {cuisines.map(c=>{
                                    return (<Form.Check
                                                key={c.toLowerCase()}
                                                onClick={()=>{switchCuisine(c);}}
                                                inline
                                                label={c}
                                                name="cuisine"
                                                type="checkbox"
                                                id={`${c.toLowerCase()}-checkbox`}
                                            />)
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h6>Sort</h6></Accordion.Header>
                        <Accordion.Body>
                                <Form.Select onChange={(e)=>updateSort(e.target.value)}>
                                    {sortTypes.map(s => {
                                        return <option key={s} value={s}>{s}</option>
                                    })}
                                </Form.Select>
                                <Form.Check 
                                    type="switch"
                                    id="ascending"
                                    label="Ascending"
                                    onChange={()=>setAscending(!ascending)}
                                />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h6>Type of dish</h6></Accordion.Header>
                        <Accordion.Body>
                            <Form.Select onChange={(e)=>setFilterType(e.target.value)}>
                                {dishTypes.map(t => {
                                    return <option key={t} value={t}>{t}</option>
                                })}
                            </Form.Select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h6>Name</h6></Accordion.Header>
                        <Accordion.Body>
                            <Form.Control 
                                    list="names" 
                                    type="text" 
                                    placeholder="Enter name"
                                    onChange={(e) => updateName(e.target.value)}
                                />
                                <datalist id="names">
                                    {names.map(n => {
                                        return <option key={n.id} value={n.title}/>
                                    })}
                                </datalist>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Form>
        </Row>
        <Row>
            <Stack direction="horizontal" gap={2}>
                <Button onClick={searchMeals}>Search</Button>
                <Button variant="secondary" onClick={onCancel}>Cancel</Button>
            </Stack>
        </Row>
    </Container>)
}