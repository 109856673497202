import { useCallback, useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup"
import { useSession } from "../../contexts/UserProvider";
import { AddInList } from "../common/AddInList";
import { UserInlineAdd } from "./UserInlineAdd";
import { UserInlineEdit } from "./UserInlineEdit";

export function UserPanelEdit({members, updateMember=f=>f, addMember=f=>f}){

    const [addMemberLine, setAddMemberLine] = useState();
    const [alert, setAlert] = useState();
    const {userId} = useSession();

    useEffect(() => {
        console.log("in edit pane");
        console.log(members);
    }, [members]);

    const add = useCallback((user) => {
        setAddMemberLine();
        const member = {user:user, isOwner:false};
        addMember(member);
    }, [members, addMember]);

    const createAddMemberLine = useCallback(() => {
        setAddMemberLine(<UserInlineAdd onAdd={add} onError={setAlert}/>);
    }, [add, addMember, setAddMemberLine, setAlert]);

    return (<div className="userPanel">
            <ListGroup variant="flush">{
                    members.map(m => {
                        return <ListGroup.Item data-cy="user-inline-edit" key={m.user.id}><UserInlineEdit member={m} isLoggedIn={m.user.id===userId} onUpdate={updateMember}/></ListGroup.Item>;
                    })
                }
                {addMemberLine}
                <AddInList onAdd={createAddMemberLine}/>
            </ListGroup>
            {alert}
        </div>
    );
}