import { useCallback, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";  
import {useFilterState} from "../../contexts/HouseholdProvider";
import { InlineField } from "../layouts/InlineField";


export function HouseholdFilter(){
    const {memberships, selectedHousehold, updateSelected} = useFilterState();
    const [filter, setFilter] = useState(selectedHousehold?selectedHousehold.id:0);

    const setHousehold = useCallback((e) => {
        let value = Number.parseInt(e.target.value);
        setFilter(value);
        if(value===0){
            updateSelected();
        } else {
            memberships.forEach(m => {
                if(m.household.id === value){
                    updateSelected(m.household);
                }
            });
        }
    }, [memberships, updateSelected]);

    const setFirstValue = useCallback(()=>{
        if(selectedHousehold){
            return <option key={selectedHousehold.id} value={selectedHousehold.id}>{selectedHousehold.name}</option> 
        }
        return;
    },[selectedHousehold])

    return (
        <div className="householdFilter">
        <InlineField title={"Select household:"} element={
            <Form.Select onChange={setHousehold}>
                {setFirstValue()}  
                <option key={0} value={0}>All</option>
                {memberships.filter(m=>m.household.id!==filter).map(m=> {
                    return (<option key={m.household.id} value={m.household.id}>{m.household.name}</option>)
                })}
            </Form.Select>
        }/>
        </div>
    );

    return (
        <Form.Group as={Row}>
            <Form.Label column>Select household:</Form.Label>
            <Col>
                <Form.Select onChange={setHousehold}>
                    {setFirstValue()}  
                    <option key={0} value={0}>All</option>
                    {memberships.filter(m=>m.household.id!==filter).map(m=> {
                        return (<option key={m.household.id} value={m.household.id}>{m.household.name}</option>)
                    })}
                </Form.Select>
            </Col>
        </Form.Group>
    );
}