export function useFormValidation(setValidated,...customFunctions){

    const validationFunction = (event, states) => {

        const form = event.currentTarget;
        let validity=true;
        event.preventDefault();
        if (form.checkValidity() === false) {
            console.log("general validity");
            setValidated(true);
            event.stopPropagation();
            validity = false;
        }

        if(!states.every(s => s.valid)){
            console.log("field validity");
            setValidated(false);
            validity = false;
        }

        if(!customFunctions.every(f => f(states))){
            console.log("custom validity");
            setValidated(false);
            validity = false;
        }

        return validity;
    }
  
    return validationFunction
}