import {HouseholdProvider} from "../contexts/HouseholdProvider"
import { RecipeProvider } from "../contexts/RecipeProvider";
import {MainPage} from "../components/common/MainPage";
import { MealProvider } from "../contexts/MealProvider";

function Main(){

    return(
        <HouseholdProvider>
            <MealProvider>
                <RecipeProvider>
                    <MainPage/>
                </RecipeProvider>
            </MealProvider>
        </HouseholdProvider>
    )
}

export {Main}