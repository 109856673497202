import { HouseholdContext } from "../../contexts/HouseholdProvider";
import { useContext, useMemo, useCallback } from "react";
import { Aggregator } from "../layouts/Aggregator";
import { InlineHousehold } from "./InlineHousehold";

function HouseholdList({selectedHousehold, select=(f)=>f, createHousehold=f=>f}){

    const {memberships, loading} = useContext(HouseholdContext);
    let selectedHouseholdIndex;

    const membershipComponents = useMemo(() => {
        if(loading) return [];
        return memberships.map((m, index) => {
            if(selectedHousehold && m.household.id === selectedHousehold.id){
                selectedHouseholdIndex = index;
            }
            return <InlineHousehold membership={m} />
        });
    }, [memberships, loading]);

    const onSelectHousehold = useCallback((index) => {
        select(memberships[index].household);
    }, [select, memberships]);

    if(loading) return "loading...";

    return(
        <Aggregator 
            list={membershipComponents}
            selectedIndex={selectedHouseholdIndex}
            setSelected={onSelectHousehold}
            showCreate={true}
            onAdd={createHousehold}
        />
    )
}

export {HouseholdList}