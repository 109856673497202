const emailValidator = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");

export function useEmailValidation(setState){
    const setEmail = (email)=>{
        if(emailValidator.test(email)){
            setState({valid:true, value:email, message:""});
        } else {
            setState({valid:false, value:email, message:"Please enter a valid email."});
        }
    };

    return setEmail;
}