import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useEffect, useState } from "react";
import { DetailView } from "../layouts/DetailView";
import { InlineField } from "../layouts/InlineField"
import { useFormValidation } from "../../hooks/useFormValidation";
import { NameInput } from "../form/NameInput";
import { DateInput } from "../form/DateInput";
import { NumberInput } from "../form/NumberInput";
import { useMealForm } from "../../contexts/MealFormContext";

function MealForm({onSaveMeal=(f)=>f, onCancel=f=>f}){
    const {nameState, setName, dateState, setDate, numberOfPeopleState, setNumber} = useMealForm();
    const states = [nameState, dateState, numberOfPeopleState];
    const [validated, setValidated] = useState(false);
    const isFormValid = useFormValidation(setValidated);

    const onSubmit = (e) => {
        if(!isFormValid(e, states)){
            return;
        }

        let fields = {
            name:nameState.value,
            date:dateState.value,
            numberOfPeople:numberOfPeopleState.value
        }
        onSaveMeal(fields);
    }

    let fields = <>
        <InlineField title={"Name:"} element={
            <NameInput placeholder="Enter name" required={true} setName={setName} {...nameState}/>
        } />
        <InlineField title={"Date:"} element={
            <DateInput required={true} setDate={setDate} {...dateState}/>
        }/>
        <InlineField title={"Number of people:"} element={
            <NumberInput required={true} setNumber={setNumber} {...numberOfPeopleState}/>
        } />
    </>

    let buttons=<>
        <Button variant="primary" type="submit">Submit</Button>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
    </>

    return (
        <Form className="mealForm" noValidate validated={validated} onSubmit={onSubmit}>
            <DetailView
                fields={fields}
                buttons={buttons}
            />
        </Form>
    );
}

export {MealForm}