import Stack from "react-bootstrap/Stack";

export function DetailView({fields, buttons}){

    return (
        <div className="detailView">
            <div className="fields">
                {fields}
            </div>
            <Stack direction="horizontal" gap={2}>
                {buttons}
            </Stack>
        </div>
    );
}