import { MealInline } from "./MealInline";
import { useCallback, useMemo } from "react";
import { Aggregator } from "../layouts/Aggregator";


export function UpcomingMeals({meals, selectedMeal, setSelected=f=>f, showCreate, createMeal=f=>f}){

    let selectedIndex;
    const mealsComponents = useMemo(() => {
        return meals.map((m, index) => {
            if(selectedMeal && m.id === selectedMeal.id){
                selectedIndex=index;
            }
            return <MealInline meal={m}/>
        }
    )}, [meals, selectedMeal]);
    
    const onSelectMeal = useCallback((index) => {
        setSelected(meals[index]);
    }, [setSelected, meals]);

    return (
        <Aggregator
            list={mealsComponents}
            selectedIndex={selectedIndex}
            setSelected={onSelectMeal}
            showCreate={showCreate}
            onAdd={createMeal}
        />
    );
    
    /*
    return(
        <div className="upcomingMeals">
            <ListGroup variant="flush">
                {meals.map(m => {
                    if(selectedMeal && m.id === selectedMeal.id){
                        return <ListGroup.Item active action onClick={() => setSelected(m)} key={m.id}><MealInline meal={m}/></ListGroup.Item>
                    } else {
                        return <ListGroup.Item action onClick={() => setSelected(m)} key={m.id}><MealInline meal={m}/></ListGroup.Item>
                    }
                })}
                {showCreate?<ListGroup.Item action onClick={createMeal} key="create"><Image className="addMealImage" src={add}></Image></ListGroup.Item>:null}
            </ListGroup>
        </div>
    );*/
}