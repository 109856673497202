export function ListItem({title, subtitle, rightSide, data_cy}){

    return (
        <div className="listItem">
            <div className="leftSide">
                <h6>{title}</h6>
                <p>{subtitle}</p>
            </div>
            <div className="rightSide">
                {rightSide}
            </div>
        </div>
    );
}