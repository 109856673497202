import { useState, useCallback, useContext } from "react";

import { HouseholdFilter } from "../household/HouseholdFilter";
import { UpcomingMeals } from "./UpcomingMeals";
import { MealEditPane } from "./MealEditPane";
import { MealContext } from "../../contexts/MealProvider";
import { useSelectedHousehold } from "../../contexts/HouseholdProvider";
import { AggregatorDetail } from "../layouts/AggregatorDetail";
import { AggregatorHeader } from "../layouts/AggregatorHeader";

export function MealOverview(){
    const {meals, selectedMeal, setSelectedMeal} = useContext(MealContext)
    const [showCreate, setShowCreate] = useState(false);

    const {selectedHousehold} = useSelectedHousehold();

    const selectMeal = useCallback((meal)=>{
        setSelectedMeal(meal);
        setShowCreate(false);
    }, [setSelectedMeal, setShowCreate]);

    const createNew = useCallback(() => {
        setSelectedMeal();
        setShowCreate(true);
    }, [setSelectedMeal, setShowCreate])

    const onDeleteMeal = useCallback(() => {
        setSelectedMeal();
    }, [setSelectedMeal]);

    const leftPane = <UpcomingMeals meals={meals} selectedMeal={selectedMeal} setSelected={selectMeal} showCreate={selectedHousehold?true:false} createMeal={createNew}/>;
    const rightPane = <MealEditPane meal={selectedMeal} showCreate={showCreate} onDeleteMeal={onDeleteMeal} defaultView={"Choose meal from the list"} />;
    const header = <AggregatorHeader action={<HouseholdFilter />}/>;

    return (
        <AggregatorDetail 
            header = {header}
            leftPane = {leftPane}
            rightPane = {rightPane}
        />
    );
}