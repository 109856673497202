import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export function AggregatorDetail({leftPane, rightPane, header}){
    return (
        <Container fluid={true}>
            <Row>
                {header}
            </Row>
            <Row>
                <Col xs="3">
                    {leftPane}
                </Col>
                <Col>
                    {rightPane}
                </Col>
            </Row>
        </Container>
    );
}