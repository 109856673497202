import axios from "axios";

const { REACT_APP_SPOON_API } = process.env;

export async function loadRecipes(filters) {
    
    if(!("sort" in filters)) {
        filters["sort"] = "random";
    }
    filters["addRecipeInformation"] = true;
    
    return axios.get(`https://api.spoonacular.com/recipes/complexSearch?number=15&apiKey=${REACT_APP_SPOON_API}`, {params:filters})
        .then(data => data.data)
        .catch(err => console.log(err));
};

export async function autoComplete(name){
    return axios.get(`https://api.spoonacular.com/recipes/autocomplete?number=15&query=${name}&apiKey=${REACT_APP_SPOON_API}`)
        .then(data => data.data)
        .catch(err => console.log(err));
};

export async function getRecipeById(id){
    return axios.get(`https://api.spoonacular.com/recipes/${id}/information?apiKey=${REACT_APP_SPOON_API}`)
        .then(data=> data.data)
        .catch(err => console.log(err));
}