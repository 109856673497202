import { InlineField } from "../layouts/InlineField";
import { DetailView } from "../layouts/DetailView";
import Button from "react-bootstrap/Button";

export function MealDetails({meal, showView=f=>f, deleteMeal=f=>f}){
    
    let fields = <>
        <InlineField title={"Name:"} element={<h6>{meal.name}</h6>} />
        <InlineField title={"Date:"} element={<p className="secondary">{`${meal.date.getDate()}-${meal.date.getMonth()+1}-${meal.date.getFullYear()} - ${String(meal.date.getHours()).padStart(2,"0")}:${String(meal.date.getMinutes()).padStart(2,"0")}`}</p>}/>
        <InlineField title={"Number of people:"} element={<p className="secondary">{meal.numberOfPeople}</p>} />
    </>

    let buttons = <>
        <Button variant="secondary" onClick={() => showView("edit")}>update</Button>
        <Button variant="danger" onClick={() => deleteMeal(meal)}>delete</Button>
    </>

    return (<DetailView
        fields={fields}
        buttons={buttons}
    />)

}