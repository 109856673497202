import { useState } from "react"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { HouseholdOverview } from "../household/HouseholdsOverview";
import { CalendarView } from "../calendar/CalendarView";
import { MealOverview } from "../meal/MealOverview";

export function MainPage(){

    const [view, setView] = useState(<HouseholdOverview />);
    const [title, setTitle] = useState("Households");

    const select = (eventKey, e) => {
        switch(eventKey){
            case "calendar": {
                setView(<CalendarView />);
                setTitle("Calendar");
                break;
            }
            case "meals": {
                setView(<MealOverview />);
                setTitle("Meals")
                break;
            }
            default:{
                setView(<HouseholdOverview/>);
                setTitle("Households");
            }
        }
    }

    return (
        <>
        <header>{title}</header>
        <div data-cy="main-page" className="mainContainer">
            <aside sm="3">
                <Nav className="navBar" defaultActiveKey="households" onSelect={select}>
                    <Nav.Link data-cy="household-nav" eventKey="households">Households</Nav.Link>
                    <Nav.Link data-cy="calendar-nav" eventKey="calendar">Calendar</Nav.Link>
                    <Nav.Link data-cy="meals-nav" eventKey="meals">Meals</Nav.Link>
                </Nav>
            </aside>
            <main>
                {view}
            </main>
        </div>
        </>
    )
}