import { ListItem } from "../layouts/ListItem";
import { MemberBadge } from "./MemberBadge";

export function UserInline({member}){
    const badge = <MemberBadge isOwner={member.isOwner}/>;
    return (
        <ListItem
            title={`${member.user.firstName} ${member.user.lastName}`}
            rightSide={badge}
        />
    );
}