import Form from "react-bootstrap/Form"

export function NumberInput({required, setNumber=f=>f, minValue, valid, value, message}){

    const props = {required: required, min:{minValue}, value: value}
    
    return <Form.Group>
        <Form.Control 
            type="number" 
            onChange={(e) => setNumber(e.target.value)} 
            {...props}
            isInvalid={!valid}
        />
        <Form.Control.Feedback type="invalid">
            {message}
        </Form.Control.Feedback>
    </Form.Group>
}