import { axios, setAuthToken } from ".";

const schedules = "/schedules";

export async function getMealsInSchedule(scheduleId){
    return axios.get(`${schedules}/${scheduleId}/meals`)
        .then(data => {
            setAuthToken(data.data.token);
            return data.data.content;
        })
        .then(data => {
            data.forEach(meal => meal.date = new Date(meal.date));
            return data;
        });
}