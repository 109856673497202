import { useEffect, useState, useContext } from "react";

import { RecipeContext } from "../../contexts/RecipeProvider";

import {ListItem} from "../layouts/ListItem";

export function MealInline({meal}){
    const [recipe, setRecipe] = useState();
    const {getRecipe} = useContext(RecipeContext);

    useEffect(()=>{
        if(meal.spoontacularId){
            getRecipe(meal.spoontacularId)
                .then(data => setRecipe(data));
        }
    }, [meal, setRecipe])

    let dateView = <div className="date">
        {`${meal.date.getDate()}-${meal.date.getMonth()+1}-${meal.date.getFullYear()}`}
        <br/>
        {`${String(meal.date.getHours()).padStart(2,"0")}:${String(meal.date.getMinutes()).padStart(2,"0")}`}
    </div>;

    return (
        <ListItem
            title={meal.name}
            subtitle={recipe?recipe.title:"Empty"}
            rightSide={dateView}
        />
    );
}