import { DetailView } from "../layouts/DetailView";
import { InlineField } from "../layouts/InlineField";
import Button from "react-bootstrap/Button";

export function HouseholdDetails({household, isOwner, onEdit=f=>f, onDelete=f=>f}){
    let fields = <>
        <InlineField title={"Name:"} element={<h6 data-cy="household-name">{household.name}</h6>} />
    </>

    let buttons = <>
        <Button data-cy="edit-button" variant="secondary" onClick={onEdit} disabled={!isOwner}>update</Button>
        <Button data-cy="delete-button" variant="danger" onClick={onDelete} disabled={!isOwner}>delete</Button>
    </>

    return (
        <DetailView 
            fields={fields}
            buttons={buttons}
        />
    )
}