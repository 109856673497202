import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import add from "../../images/plus-svg.svg"


export function Aggregator({list, selectedIndex, setSelected=f=>f, showCreate, onAdd=f=>f}){

    return (<div className="aggregator">
        <ListGroup variant="flush">
            {list.map((element, i) => {
                if(selectedIndex && i === selectedIndex){
                    return <ListGroup.Item data-cy="list-item" active action onClick={() => setSelected(i)} key={i}>{element}</ListGroup.Item>
                } else {
                    return <ListGroup.Item data-cy="list-item" action onClick={() => setSelected(i)} key={i}>{element}</ListGroup.Item>
                }
            })}
            {showCreate?<ListGroup.Item data-cy="add-item" action onClick={onAdd} key="create"><Image className="addMealImage" src={add}></Image></ListGroup.Item>:null}
        </ListGroup>
    </div>);
}