import { RecipeSideView } from "../recipes/RecipeSideView";
import { MealDetails } from "./MealDetails";
import { MealForm } from "./MealForm";
import { TwoSidedDetail } from "../layouts/TwoSidedDetail";

export function Meal({meal, recipe, showView=f=>f, deleteMeal=f=>f, isEditing, onSaveMeal=f=>f, onCancel=f=>f}){

    let leftPane
    if(!meal){
        leftPane=<MealForm onSaveMeal={onSaveMeal} onCancel={onCancel}/>
    } else if (isEditing){
        leftPane=<MealForm onSaveMeal={onSaveMeal} onCancel={onCancel}/>
    } else {
        leftPane=<MealDetails meal={meal} showView={showView} deleteMeal={deleteMeal}/>
    }

    let recipeId;
    if(meal){
        recipeId = meal.spoontacularId
    } else if(recipe){
        recipeId = recipe.id
    } else {
        recipeId = null
    }

    return (
        <TwoSidedDetail 
            leftSide={leftPane}
            rightSide={<RecipeSideView recipeId={recipeId} selectRecipe={() => showView("recipe")}/>}
        />
    );
}