import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Fade from "react-bootstrap/Fade";

import { useAuthentication, useSession } from "../../contexts/UserProvider";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailInput } from "../form/EmailInput";
import { PasswordInput } from "../form/PasswordInput";
import { useEmailValidation } from "../../hooks/useEmailValidation";
import { useFormValidation } from "../../hooks/useFormValidation";
import { Alert } from "react-bootstrap";

function LoginForm(){
    const {loginUser} = useAuthentication();
    const {loading, error} = useSession();
    const [emailState, setEmailState] = useState({valid:true, value:""});
    const setEmail = useEmailValidation(setEmailState);
    const [passwordState, setPasswordState] = useState({valid:true, value:""});
    const [alert, setAlert] = useState();
    const [validated, setValidated] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(error){
            setAlert(<Alert variant="danger">{error}</Alert>);
        }
    }, [error, setAlert]);

    const updatePassword = useCallback((pwd) => {
        setPasswordState({valid:true, value:pwd});
    }, [setPasswordState]);

    const states = [emailState, passwordState]
    const isFormValid = useFormValidation(setValidated);

    const onSubmit = useCallback(async (e) => {
        if(!isFormValid(e, states)){
            return;
        }
        loginUser(emailState.value, passwordState.value)
            .then(data => {
                if(data){
                    navigate("/")
                };
            })
    }, [emailState, passwordState, loginUser, navigate, setValidated]);

    if(loading){
        return <Spinner animation="border" variant="secondary"/>
    }

    return (
        <Form data-cy="login-form" noValidate validated={validated} onSubmit={onSubmit}>
            {alert}
            <EmailInput setEmail={setEmail} {...emailState}/>
            <PasswordInput placeholder="Password" setPassword={updatePassword} {...passwordState}/>
            <Button data-cy="login-btn" variant="primary" type="submit">
                Login
            </Button>
        </Form>
    )
}

export {LoginForm}