import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import add from "../../images/plus-svg.svg"
import { useCallback } from "react";

export function AddInList({onAdd=f=>f}){
    const click = useCallback((e) => {
        e.preventDefault();
        onAdd();
    }, []);
    return <ListGroup.Item data-cy="add-user" action onClick={click} key="create"><Image className="addMealImage" src={add}></Image></ListGroup.Item>
}