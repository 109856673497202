import Form from "react-bootstrap/Form"
import { useState, useCallback } from "react";

export function PasswordInput({placeholder, setPassword=f=>f, valid, value, message}){

    const props = {value:value, placeholder:placeholder};

    return <Form.Group>
        <Form.Control 
            data-cy="pwd-input"
            type="password" 
            {...props}
            onChange={(e) => setPassword(e.target.value)} 
            required
            isInvalid={!valid}
        />
        <Form.Control.Feedback data-cy="pwd-invalid" type="invalid">
            {message}
        </Form.Control.Feedback>
    </Form.Group>
}