import Form from "react-bootstrap/Form";
import { useCallback, useMemo } from "react";

import { ListItem } from "../layouts/ListItem";

export function UserInlineEdit({member, isLoggedIn, onUpdate=f=>f}){

    let props = useMemo(() => {
        return isLoggedIn?{disabled:true, checked:true, readOnly:true}:{defaultChecked:member.isOwner}
    }, [member, isLoggedIn]);

    const click = useCallback(() => {
        onUpdate({user:member.user, isOwner:!member.isOwner})
    }, [member]);

    const isOwnerToggle = useMemo(() => <Form.Check 
        data-cy="toggle-owner"
        type="switch"
        id="is-owner"
        label="Owner"
        onChange={click}
        {...props}
    />, [props])

    return (
        <ListItem
            title={member.user.firstName?`${member.user.firstName} ${member.user.lastName}`:member.user.email}
            rightSide={isOwnerToggle}
        />
    );
}