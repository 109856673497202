import Button from "react-bootstrap/Button";
import { useState, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";

import { useAuthentication, useSession } from "../../contexts/UserProvider";
import { EmailInput } from "../form/EmailInput";
import { NameInput } from "../form/NameInput";
import { PasswordInput } from "../form/PasswordInput";
import { useNameValidation } from "../../hooks/useNameValidation";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { useEmailValidation } from "../../hooks/useEmailValidation";
import { useFormValidation } from "../../hooks/useFormValidation";
import Alert from "react-bootstrap/Alert";

export function RegisterForm({onRegister=f=>f}){

    const {registerUser} = useAuthentication();
    const {loading, error} = useSession();
    const [alert, setAlert] = useState();

    const [firstNameState, setFirstNameState] = useState({valid:true, value:""});
    const setFirstName = useNameValidation(setFirstNameState);

    const [lastNameState, setLastNameState] = useState({valid:true, value:""});
    const setLastName = useNameValidation(setLastNameState);

    const [emailState, setEmailState] = useState({valid:true, value:""});
    const setEmail = useEmailValidation(setEmailState);

    const [passwordState, setPasswordState] = useState({valid:true, value:""});
    const setPassword = usePasswordValidation(setPasswordState);

    const [confirmPasswordState, setConfirmPasswordState] = useState({valid:true, value:""});
    const setConfirmPassword = useCallback((pwd) => {
        setConfirmPasswordState({valid:true, value:pwd});
    }, [setConfirmPasswordState]);

    const states = [firstNameState, lastNameState, emailState, passwordState, confirmPasswordState];

    const [validated, setValidated] = useState(false);

    const comparePasswords = useCallback((states) => {
        const confirmation = states[states.length-1].value;
        const password = states[states.length - 2].value;
        if(password !== confirmation){
            setConfirmPasswordState({valid:false, value:confirmation, message:"Both passwords do not match."});
            setValidated(false);
            return false;
        };
        return true;
    }, [setConfirmPasswordState, setValidated]);

    const isFormValid = useFormValidation(setValidated, comparePasswords);

    const createAlert = useCallback(() => {
        setAlert(<Alert data-cy="register-invalid" transition="fade" variant="danger">{error}</Alert>);
    }, [error]);

    const onSubmit = useCallback(async (event) => {
        if(!isFormValid(event, states)){
            return;
        }
        const firstName = firstNameState.value;
        const lastName = lastNameState.value;
        const email = emailState.value;
        const password = passwordState.value;

        const registered = await registerUser(email, password, firstName, lastName);
        if(registered){
            onRegister();
        } else {
            createAlert();
        }
    }, [...states])

    return <Form data-cy="register-form" noValidate validated={validated} onSubmit={onSubmit}>
        {alert}
        <NameInput placeholder="Enter First Name" required={true} setName={setFirstName} {...firstNameState}/>
        <NameInput placeholder="Enter Last Name" required={false} setName={setLastName} {...lastNameState}/>
        <EmailInput setEmail={setEmail} {...emailState}/>
        <PasswordInput placeholder="Enter Password" setPassword={setPassword} {...passwordState}/>
        <PasswordInput placeholder="Confirm password" setPassword={setConfirmPassword} {...confirmPasswordState}/>
        <Button data-cy="register-button" type="submit">Register</Button>
    </Form>
}