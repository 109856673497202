const nameValidator = new RegExp("^[a-zA-Z- ]*$");

export function useNameValidation(setState){
    const setName = (name)=>{
        if(nameValidator.test(name)){
            setState({valid:true, value:name, message:""});
        } else {
            setState({valid:false, value:name, message:"A name cannot contain special characters."});
        }
    };

    return setName;
}