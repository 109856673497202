import empty_meal from "../../images/empty-meal.jpeg";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { RecipeContext } from "../../contexts/RecipeProvider";
import { useEffect, useState } from "react";
import { BadgeDisplay } from "./BadgeDisplay";
import { useContext } from "react";

export function RecipeCard({recipeId, selectRecipe=f=>f, isNew}){

    const {getRecipe} = useContext(RecipeContext);

    const [recipe, setRecipe] = useState();
    const [loading, setLoading] = useState(false);
    let title
    let link
    let text
    let btnText

    useEffect(()=>{
        if (recipeId){
            setLoading(true)
            getRecipe(recipeId)
                .then(data => {
                    setLoading(false);
                    setRecipe(data);
                })
            return <Card><Spinner animation="border" /></Card>
        } else {
            setRecipe();
        }
    }, [recipeId]);

    if(recipe){
        title = recipe.title;
        link = recipe.sourceUrl;
        text = <BadgeDisplay dishTypes={recipe.dishTypes} diets={recipe.diets}/>
        btnText = isNew?"Choose":"Change";
    } else {
        title = "Choose a recipe";
        text = "This meal has no recipe yet, you can view the available recipes by clicking the choose button below.";
        btnText = "Choose";
    }

    return (
        <Card className="recipeCard">
            <Card.Img variant="top" src={recipe?recipe.image:empty_meal} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
                <Button variant="success" onClick={() => selectRecipe(recipe)}>
                    {btnText}
                </Button>
            </Card.Body>
        </Card>
    )
}