import "./App.css";
import { Main } from "./routes/Main";
import { Login } from "./routes/Login";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./components/authentication/PrivateRoute";
import { UserProvider } from "./contexts/UserProvider";

function App() {

  return (
      <UserProvider>
        <Routes>
          <Route exact path="/" element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }/>
          <Route exact path="/login" element={<Login />}/>
        </Routes>
      </UserProvider>
  );
}

export default App;
