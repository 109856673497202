import { useState, useCallback, useContext, useEffect } from "react";

import { Meal } from "./Meal";
import { RecipeOverview } from "../recipes/RecipeOverview";
import { MealContext } from "../../contexts/MealProvider";
import { MealFormProvider } from "../../contexts/MealFormContext";

export function MealEditPane({meal, showCreate, defaultView, onDeleteMeal=f=>f, afterCancel=f=>f}){
    const {updateMealWithFields, deleteMealById, updateMeals, createMealWithFields} = useContext(MealContext)
    const [editPane, setEditPane] = useState("");
    const [selectedRecipe, setSelectedRecipe] = useState();

    useEffect(()=>{
        if(showCreate){
            setEditPane("new");
        } else if(meal){
            setEditPane("detail");
        }
    }, [meal, showCreate]);

    const createMeal = useCallback(async (fields)=>{
        if(selectedRecipe){
            console.log("This had a recipe");
            fields.spoontacularId = selectedRecipe.id;
            setSelectedRecipe()
        }
        await createMealWithFields(fields);
        updateMeals();
    }, [updateMeals, createMealWithFields, selectedRecipe, setSelectedRecipe])

    const deleteMeal = useCallback(async (meal) => {
        await deleteMealById(meal.id);
        setEditPane("default");
        onDeleteMeal();
        updateMeals();
    }, [onDeleteMeal, updateMeals, deleteMealById]);

    const updateMeal = useCallback(async (fields) => {
        await updateMealWithFields(fields, meal);
        await updateMeals();
        setEditPane("detail");

    }, [meal, updateMeals, updateMealWithFields]);

    const addRecipeToMeal = useCallback(async (recipe) => {
        if(meal){
            meal.spoontacularId = recipe.id;
            updateMeal({spoontacularId: recipe.id});
        } else {
            setSelectedRecipe(recipe)
            setEditPane("new");
        }
    }, [setSelectedRecipe, setEditPane])

    const onCancel = useCallback(() => {
        if(meal && meal.name){
            setEditPane("detail");
        } else {
            setEditPane("default");
        }
        afterCancel();
    }, [meal, setEditPane])

    const showEditPanel = useCallback(() => {
        switch(editPane){
            case "new": return <Meal meal={meal} recipe={selectedRecipe} isEditing={true} deleteMeal={deleteMeal} showView={setEditPane} onSaveMeal={createMeal} onCancel={onCancel}/>;
            case "detail": return <Meal meal={meal} deleteMeal={deleteMeal} showView={setEditPane}/>;
            case "edit": return <Meal meal={meal} isEditing={true} deleteMeal={deleteMeal} showView={setEditPane} onSaveMeal={updateMeal} onCancel={onCancel}/>;
            case "recipe": return <RecipeOverview addRecipeToMeal={addRecipeToMeal} onCancel={onCancel}/>
            default: return defaultView;
        }
    }, [editPane, meal, deleteMeal, setEditPane, createMeal, updateMeal, defaultView, onCancel]);

    return <MealFormProvider>
        {showEditPanel()}
    </MealFormProvider>
}