import ListGroup from "react-bootstrap/ListGroup"
import { UserInline } from "./UserInline"

export function UserPanel({members}){
    return (<div className="userPanel">
            <ListGroup variant="flush">{
                    members.map(m => {
                        return <ListGroup.Item key={m.user.id}><UserInline member={m}/></ListGroup.Item>;
                    })
                }
            </ListGroup>
        </div>
    );
}