const validateNumber = new RegExp("[0-9]");
const validateLowerCase = new RegExp("[a-z]");
const validateUpperCase = new RegExp("[A-Z]");
const validateSpecialChar = new RegExp("[!@#$%^&*]");

export function usePasswordValidation(setState){
    const setPassword = (pwd)=>{
        let message = "A password must contain: ";
        let first = true;
        let valid = true
        if(!validateNumber.test(pwd)){
            message += (first?"":", ") + "a number";
            first = false;
            valid = false;
        } 
        if(!validateLowerCase.test(pwd)){
            message += (first?"":", ") + "a lower case letter";
            first = false;
            valid = false;
        }
        if(!validateUpperCase.test(pwd)){
            message += (first?"":", ") + "an upper case letter";
            first = false;
            valid = false;
        }
        if(!validateSpecialChar.test(pwd)){
            message += (first?"":", ") + "a special character (!,@,#,$,%,^,&,*)";
            first = false;
            valid = false;
        }

        if(pwd.length < 8){
            message += " and must be at least 8 charachters long."
            valid=false;
        }

        setState({valid:valid, value:pwd, message:message});
    };

    return setPassword;
}