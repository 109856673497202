import { createContext, useMemo, useState, useCallback, useEffect, useContext } from "react";
import { login, register, findByEmail } from "../api/users";
import { setAuthToken } from "../api";

const UserContext = createContext();

const useAuth = () => useContext(UserContext); 

export const useSession = () => {
	const { loading, token, userId, ready, error } = useAuth();
	return { loading, token, userId, ready, error };
};

export const useIsAuthenticated = () => {
    const {token} = useAuth();
    if(token){
        return true;
    } else {
        return false;
    }
}

export const useAuthentication = () => {
	const { loginUser, logOut, registerUser } = useAuth();
	return {loginUser, logOut, registerUser};
};

export const useFindByEmail = () => {
    const {findUserByEmail} = useAuth();
    return findUserByEmail;
}

function parseJwt(token){
    if (!token) return {};
	const base64Url = token.split('.')[1];
	const payload = Buffer.from(base64Url, 'base64');
	const jsonPayload = payload.toString('ascii');
	return JSON.parse(jsonPayload);
}

function parseDate(dateExpr){
    if (!dateExpr) return null;
	if (typeof dateExpr !== 'number') dateExpr = Number(dateExpr);
	if (isNaN(dateExpr)) return null;
	return new Date(dateExpr * 1000);
}

function UserProvider({children, }){

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [token, setToken] = useState(localStorage.getItem("USER_TOKEN"));
    const [userId, setUserId] = useState(0);
    const [ready, setReady] = useState(false);

    const setSession = useCallback((token) => {
		let { id, exp } = parseJwt(token);
		const expiry = parseDate(exp);
		const stillValid = expiry >= new Date();

		if (stillValid) {
			localStorage.setItem("USER_TOKEN", token);
		} else {
			localStorage.removeItem("USER_TOKEN");
			token = null;
            id = 0;
		}

		setAuthToken(token);
		setToken(token);
        setUserId(id);
		setReady(stillValid);
	}, []);

    useEffect(() => {
        setSession(token);
    }, [token, setSession]);

    const loginUser = useCallback(async (email, password) => {
        try{
            setLoading(true);
            setError("");
            const data = await login(email, password);
            setSession(data.token);
            return true;
        } catch (error) {
            setError("Email or password was incorrect");
            console.log(error); 
            return false;
        } finally {
            setLoading(false);
        }
    }, [setSession]);

    const registerUser = useCallback(async (email, password, firstName, lastName) => {
        try{
            setLoading(true);
            setError();
            const data = await register({email:email, password:password, firstName:firstName, lastName:lastName});
            return true;
        } catch (error) {
            console.log(error);
            setError("User email already taken");
        } finally {
            setLoading(false);
        }
    }, [setLoading, setError]);

    const logOut = useCallback(() => {
        setSession(null);
    }, [setSession]);

    const findUserByEmail = useCallback(async (email) => {
        return findByEmail(email)
            .catch(err => -1);
    }, [])

    const value = useMemo(() => ({
        token,
        userId,
        error, 
        loading,
        ready,
        loginUser,
        registerUser,
        logOut,
        findUserByEmail
    }), [token, userId, error, loading, ready, registerUser, loginUser, logOut, findUserByEmail]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export {UserContext, UserProvider}