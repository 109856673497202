import { axios, setAuthToken } from ".";

const households = "/households";

export async function getHousehold(householdId) {
    const data = await axios.get(`${households}/${householdId}`);
    setAuthToken(data.data.token);
    return data.data.content;
}

export async function getHouseholdMembers(id){
    const data = await axios.get(`${households}/${id}/members`);
    setAuthToken(data.data.token);
    return data.data.content;
}

export async function getHouseholdSchedules(id){
    const data = await axios.get(`${households}/${id}/schedules`);
    setAuthToken(data.data.token);
    return data.data.content;
}

export async function createHousehold(name, userId){
    return axios.post(`${households}/${userId}`,{
        name:name
    }).then(data => {
        setAuthToken(data.data.token);
        return data.data.content;
    });   
}

export async function deleteHousehold(id){
    return await axios.delete(`${households}/${id}`);
}

export async function addMember(householdId, userId){
    return axios.put(`${households}/${householdId}/addMember/${userId}`)
        .then(data => {
            setAuthToken(data.data.token);
            return data.data.content;
        });
}

export async function addOwner(householdId, userId){
    return axios.put(`${households}/${householdId}/addOwner/${userId}`)
        .then(data => {
            setAuthToken(data.data.token);
            return data.data.content;
        });
}

export async function revokeOwner(householdId, userId){
    return axios.put(`${households}/${householdId}/revokeOwner/${userId}`)
        .then(data => {
            setAuthToken(data.data.token);
            return data.data.content;
        });
}

export async function updateHousehold(householdId, name){
    return axios.put(`${households}/${householdId}`, {
        name:name
    }).then(data => {
        setAuthToken(data.data.token);
        return data.data.content;
    })
}
