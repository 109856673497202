import { HouseholdList } from "./HouseholdList";

import { useFilterState } from "../../contexts/HouseholdProvider";
import { useState , useCallback} from "react";
import { Household } from "./Household";
import { HouseholdForm } from "./HouseholdForm";
import { AggregatorDetail } from "../layouts/AggregatorDetail";
import { AggregatorHeader } from "../layouts/AggregatorHeader";
import { HouseholdFormProvider } from "../../contexts/HouseholdFormContext";

export function HouseholdOverview(){

    const {updateSelected, selectedHousehold} = useFilterState();
    const [detailState, setDetailState] = useState("Select the a household from the list");

    const selectHousehold = useCallback((household) => {
        updateSelected(household); 
        setDetailPane("detail", household);
    }, [])

    const resetDetailPane = () => {
        setDetailPane();
    }

    const setDetailPane = (type, household=null) => {
        switch(type){   
            case "create": {
                setDetailState(<HouseholdFormProvider>
                    <HouseholdForm household={household} afterUpdate={setDetailPane} onCancel={resetDetailPane}/>
                </HouseholdFormProvider>);
                break;
            }
            case "detail": {
                setDetailState(<Household afterDelete={resetDetailPane} updatePane={setDetailPane}/>);
                break;
            }
            default: {
                setDetailState("Select the a household from the list");
            }
        }
    }

    const addNew = useCallback(() => {
        updateSelected();
        setDetailPane("create");
    }, [setDetailPane]);

    const leftPane = <HouseholdList selectedHousehold={selectedHousehold} select={selectHousehold} createHousehold={addNew}/>;
    const header = <AggregatorHeader />

    return (
        <AggregatorDetail 
            leftPane = {leftPane}
            rightPane = {detailState}
            header = {header}
        />
    );
}