import { useCallback, useEffect, useState, useMemo, useContext } from "react";

import { useHouseholdCRUD, useSelectedHousehold } from "../../contexts/HouseholdProvider";
import { useSession } from "../../contexts/UserProvider"; 
import { TwoSidedDetail } from "../layouts/TwoSidedDetail";
import { HouseholdDetails } from "./HouseholdDetails";
import { UserPanel } from "../user/UserPanel";

const isUserOwner = (userId, members) => {
    if(!members){
        return false;
    }
    return members.filter(m => m.user.id===userId)[0].isOwner;
}

export function Household({afterDelete=f=>f, updatePane=f=>f}){
    const {deleteHouseholdById} = useHouseholdCRUD();
    const {selectedHousehold, getMembers} = useSelectedHousehold();
    const {userId} = useSession();

    const [loadingMembers, setLoadingMembers] = useState(true);
    const [members, setMembers] = useState();

    useEffect(() => {
        if(selectedHousehold){
            setLoadingMembers(true);
            getMembers()
                .then(members => {
                    setMembers(members);
                    setLoadingMembers(false);
                });
        }
    }, [selectedHousehold]);

    let userPanel = useMemo(() => {
        if (loadingMembers){
            return "Loading...";
        } else {
            return <UserPanel members={members}/>
        }
    }, [loadingMembers, members]);

    const clickDelete = useCallback(()=>{
        deleteHouseholdById(selectedHousehold.id);
        afterDelete();
    },[selectedHousehold, afterDelete, deleteHouseholdById]);

    const toggleEdit = useCallback(()=>{
        updatePane("create", selectedHousehold);
    }, [selectedHousehold, updatePane]);

    if(!selectedHousehold) return "Select a household from the list.";

    let leftSide = <HouseholdDetails 
        household={selectedHousehold}
        isOwner={isUserOwner(userId, members)}
        onEdit={toggleEdit}
        onDelete={clickDelete}
    />;

    return (
        <TwoSidedDetail 
            leftSide={leftSide}
            rightSide={userPanel}
        />
    )
}