import axiosRoot from "axios";

const { REACT_APP_DB_URL } = process.env;

export const axios = axiosRoot.create({
    baseURL:REACT_APP_DB_URL
});

export const setAuthToken = (token) => {
    if(token){
        axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers["Authorization"];
    }
}