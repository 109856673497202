import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { revokeOwner } from "../api/households";
import { useHouseholdCRUD, useSelectedHousehold } from "./HouseholdProvider";
import { useNameValidation } from "../hooks/useNameValidation";

export const HouseholdFormContext = createContext();
export const useHouseholdForm = () => useContext(HouseholdFormContext);

export const HouseholdFormProvider = ({children}) => {

    const {createNewHousehold, addMemberToHousehold, addOwnerToHousehold, updateHouseholdName} = useHouseholdCRUD();

    const {selectedHousehold, getMembers} = useSelectedHousehold();
    const [members, setMembers] = useState([]);

    const [nameState, setNameState] = useState({valid:true, value:""});
    const setName = useNameValidation(setNameState);

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const newMembers = useMemo(()=>[],[]);
    const updateMembers = useMemo(()=>[],[]);

    useEffect(() => {
        if(selectedHousehold){
            setName(selectedHousehold.name);
            getMembers()
                .then(data => setMembers(data));
        }
    }, [selectedHousehold]);
    
    const addMember = useCallback((member) => {
        const existingUser = newMembers.filter(m => m.user.id===member.user.id)[0];
        console.log("In the provider...");
        console.log(members);
        if(!existingUser){
            newMembers.push(member);
            setMembers([...members, member]);
        } else {
            const i = newMembers.findIndex(existingUser);
            newMembers[i] = member;
            members[i] = member;
            setMembers([...members]);
        }
    }, [setMembers, members]);

    const updateExisting = useCallback(async () => {
        setLoading(true);
        try{
            let updatedHousehold = await updateHouseholdName(nameState.value);
            if(updatedHousehold === -1){
                throw Error();
            } else {
                await addUsersToHousehold(updatedHousehold.id);
                await updateMembersInHousehold(updatedHousehold.id);
                setError();
                return updatedHousehold;
            }
        } catch (error){
            setError("Unable to update household.");
        } finally {
            setLoading(false);
        }
    }, [setLoading, setError, nameState]);

    const createNew = useCallback(async () => {
        setLoading(true);
        try{
            let newHousehold = await createNewHousehold(nameState.value);
            if(newHousehold === -1){
                throw Error();
            } else {
                await addUsersToHousehold(newHousehold.id);
                setError();
                return newHousehold;
            }
        } catch (error){
            setError("Unable to update household.");
        } finally {
            setLoading(false);
        }
    }, [setLoading, setError, nameState]);

    const addUsersToHousehold = async (id) => {
        await Promise.all(newMembers.map(member => {
            if(member.isOwner){
                return addOwnerToHousehold(id, member.user.id);
            } else {
                return addMemberToHousehold(id, member.user.id);
            }
        }));
    }

    const updateMembersInHousehold = async (id) => {
        await Promise.all(updateMembers.filter(m => m.isOwner !== m.originalState).map( m => {
            if(m.isOwner){
                return addOwnerToHousehold(id, m.user.id);
            } else {
                return revokeOwner(id, m.user.id);
            }
        }));
    }

    const updateMember = (member) => {
        const updateOwner = (m) => {
            if(m.user.id === member.user.id){
                m.isOwner = member.isOwner;
            }
        }
        let originalValue = members.filter(m => m.user.id === member.user.id)[0].isOwner;
        members.forEach(updateOwner);
        newMembers.forEach(updateOwner);
        
        const updatedMember = updateMembers.filter(m => m.user.id === member.user.id)[0];
        if(!updatedMember){
            member.originalState = originalValue;
            updateMembers.push(member)
        } else {
            updateMembers.forEach(updateOwner);
        }
    }

    const value = useMemo(() => ({
        members,
        nameState,
        setName,
        addMember,
        createNew,
        updateMember,
        updateExisting
    }), [members, nameState, setName, addMember, createNew, updateMember, updateExisting]);

    return (
        <HouseholdFormContext.Provider value={value}>
            {children}
        </HouseholdFormContext.Provider>
    )
}