import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import { ListItem } from "../layouts/ListItem";
import { useEmailValidation } from "../../hooks/useEmailValidation";
import { EmailInput } from "../form/EmailInput";
import { useFindByEmail } from "../../contexts/UserProvider";

const createAlert = () => {
    return <Alert variant="danger">
        User not found.
    </Alert>
}

export function UserInlineAdd({onAdd=f=>f, onError=f=>f}){
    const find = useFindByEmail();
    const [emailState, setEmailState] = useState({valid:true, value:""});
    const setEmail = useEmailValidation(setEmailState);

    const findUser = useCallback(async () => {
        find(emailState.value)
            .then(id => {
                onError();
                if(id===-1){
                    onError(createAlert());
                    return;
                }
                onAdd({id:id, email:emailState.value});
            })
            .catch(err => {
                onError(createAlert());
                return;
            });
    }, [emailState, find, onAdd, onError]);

    const clickButton = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if(emailState.valid){
            findUser();
        }
    }, [findUser])

    const addUser = <Button data-cy="add-button" onClick={clickButton}>Add User</Button>

    const findUserControl = <EmailInput setEmail={setEmail} {...emailState}/>

    return (
        <ListGroup.Item data-cy="new-user-inline-edit">
            <ListItem
                title={findUserControl}
                rightSide={addUser}
            />
        </ListGroup.Item>
    );

}