import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

export function TwoSidedDetail({leftSide, rightSide}){
    return(<Card className="meal">
        <Container>
            <Row>
                <Col md={6} xxl={8}>
                    {leftSide}
                </Col>
                <Col md={6} xxl={4}>
                    {rightSide}
                </Col>
            </Row>
        </Container>
    </Card>);
}