import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { RecipeContext } from "../../contexts/RecipeProvider";
import { useEffect, useState } from "react";
import { BadgeDisplay } from "./BadgeDisplay";
import { Meal } from "../meal/Meal";
import { useContext } from "react";
import { SideCard } from "../layouts/SideCard";
import add from "../../images/plus-svg.svg";
import Image from "react-bootstrap/Image";


export function RecipeSideView({recipeId, selectRecipe = f=>f}){
    const {getRecipe} = useContext(RecipeContext);

    const [recipe, setRecipe] = useState();
    const [loading, setLoading] = useState(false);
    let image
    let title
    let text
    let btnText

    useEffect(()=>{
        if (recipeId){
            setLoading(true)
            getRecipe(recipeId)
                .then(data => {
                    setLoading(false);
                    setRecipe(data);
                })
        } else {
            setRecipe();
        }
    }, [recipeId]);

    if (!recipeId){
        return <SideCard empty={<>
            <Image onClick={selectRecipe} className="addMeal" src={add}/>
            <p>Select a recipe</p>
        </>}/>
    }

    if(recipe){
        image=recipe.image;
        title=recipe.title;
        text=<BadgeDisplay dishTypes={recipe.dishTypes} diets={recipe.diets}/>
        btnText="Change"
    } else {
        return <SideCard loading={<Spinner variant="secondary"/>}/>
    }

    return (
        <SideCard 
            image={image}
            title={title}
            body={text}
            button={
                <Button variant="success" onClick={selectRecipe}>
                    {btnText}
                </Button>
            }
        />
    )
}