import { useCallback, useMemo } from "react";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import background from "../../images/authentication-background.jpg"

export function AuthenticationPage(){
    const [selected, setSelected] = useState("left");
    const [alert, setAlert] = useState();

    const leftClasses = useMemo(() => "navTab left" + (selected==="left"?" selected":""), [selected]);
    const rightClasses = useMemo(() => "navTab right" + (selected==="right"?" selected":""), [selected]);

    const onRegister = useCallback(() => {
        setSelected("left");
        setAlert(<Alert transition="fade" variant="success">Succesfully registered, you can now log in.</Alert>);
    }, [setSelected, setAlert]);

    const layout = useMemo(() => {
        switch(selected){
            case "left":
                return <LoginForm />
            case "right":
                return <RegisterForm onRegister={onRegister}/>
        }
    }, [selected])

    return (
        <div className="authenticateBackground">
            <img src={background}/>
            <div className="authenticatePanel">
                <div className="authenticateHeader">
                    <div data-cy="login" onClick={() => setSelected("left")} className={leftClasses}>Login</div>
                    <div data-cy="register" onClick={() => setSelected("right")} className={rightClasses}>Register</div>
                </div>
                {alert}
                <div className="layoutHolder">
                    {layout}
                </div>
            </div>
        </div>
    )
}