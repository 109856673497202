export function SideCard({image, title, body, button, empty, loading}){

    if(empty || loading){
        return <div className="emptySidecard">
            <div>
                {empty?empty:loading}
            </div>
        </div>
    }

    return (
        <div className="sideCard">
            <img src={image}/>
            <div className="title">{title}</div>
            <div className="body">{body}</div>
            <div className="button">{button}</div>
        </div>
    )
}