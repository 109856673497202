import Form from "react-bootstrap/Form"
import { useCallback, useState } from "react";

const nameValidator = new RegExp("^[a-zA-Z- ]*$");

export function NameInput({placeholder, required, setName=f=>f, valid, value, message}){

    const props = {placeholder: placeholder, required: required, value:value}
    
    return <Form.Group>
        <Form.Control 
            data-cy="name-input"
            type="name" 
            onChange={(e) => setName(e.target.value)} 
            {...props}
            isInvalid={!valid}
        />
        <Form.Control.Feedback data-cy="invalid-name" type="invalid">
            {message}
        </Form.Control.Feedback>
    </Form.Group>
}