import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useCallback, useContext, useEffect, useState} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { HouseholdFilter } from "../household/HouseholdFilter";
import { MealContext } from "../../contexts/MealProvider";
import { MealEditPane } from "../meal/MealEditPane";
import { AggregatorHeader } from "../layouts/AggregatorHeader";

import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);

export function CalendarView(){

    const {meals} = useContext(MealContext)
    const [selectedMeal, setSelectedMeal] = useState();
    const [showCreate, setShowCreate] = useState(false);
    const [calendarOptions, setCalendarOptions] = useState();

    let state = {
        events: meals.map(meal => {
            let startTime = moment(meal.date);
            let event = {
                start: startTime.toDate(),
                end: startTime.add(1, "hours").toDate(),
                title: meal.name,
                meal: meal
            }
            return event;
        })
    };

    const clickOnMeal = useCallback((e) => {
        setSelectedMeal(e.meal);
        console.log("setting to false");
        setShowCreate(false);
    }, [setSelectedMeal, setShowCreate]);

    const selectSlot = useCallback((e) => {
        if(calendarOptions.view==="day"){
            setSelectedMeal({date:e.start.toISOString()});
            console.log("setting to true");
            setShowCreate(true);
        } else {
            calendarOptions.view="day";
            setCalendarOptions({...calendarOptions});
        }
    }, [setShowCreate, calendarOptions, setCalendarOptions]);

    const afterCancel = useCallback(() => {
        setShowCreate(false);
        setSelectedMeal();
    }, [showCreate]);

    useEffect(()=>{
        setCalendarOptions({
            defaultDate:new Date(),
            view:"month"
        });
        setSelectedMeal();
    }, [])

    const onView = useCallback((e) => {
        calendarOptions.view=e
        setCalendarOptions({...calendarOptions});
        console.log("setting to false");
        setShowCreate(false);
    }, [calendarOptions, setCalendarOptions, setShowCreate]);

    return (
        <Container>
            <Row>
                <AggregatorHeader action={<HouseholdFilter />}/>
            </Row>
            <Row>
                <Col>
                    <Calendar
                        {...calendarOptions}
                        onSelectSlot={selectSlot}
                        selectable
                        onView={onView}
                        views={['month', 'week', 'day']}
                        defaultView="month"
                        localizer={localizer}
                        events={state.events}
                        style={{ height: "100vh" }}
                        onSelectEvent={clickOnMeal}
                    ></Calendar>
                </Col>
                {(selectedMeal||showCreate)?<Col><MealEditPane meal={selectedMeal} showCreate={showCreate} defaultView={null} afterCancel={afterCancel}/></Col>:null}
            </Row>
        </Container>
    );
}