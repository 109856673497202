import Form from "react-bootstrap/Form"

export function EmailInput({setEmail=f=>f, valid, value, message}){

    const props = {value:value, placeholder:"Enter email"};

    return <Form.Group>
        <Form.Control 
            data-cy="email-input"
            type="email" 
            {...props}
            onChange={(e) => setEmail(e.target.value)} 
            required
            isInvalid={!valid}
        />
        <Form.Control.Feedback type="invalid">
            {message}
        </Form.Control.Feedback>
    </Form.Group>
}