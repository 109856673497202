import { useState, useMemo, createContext, useContext, useCallback, useEffect } from "react";
import { useNameValidation } from "../hooks/useNameValidation";
import { useSelectedMeals } from "./MealProvider";

export const MealFormContext = createContext();
export const useMealForm = () => useContext(MealFormContext);

export const MealFormProvider = ({children}) => {
    
    const {selectedMeal} = useSelectedMeals();

    useEffect(() => {
        if(selectedMeal){
            setNameState({valid:true, value:selectedMeal.name});
            setDateState({valid:true, value:selectedMeal.date.toISOString().slice(0,-1)});
            setNumberState({valid:true, value:selectedMeal.numberOfPeople});
        } else {
            setNameState({valid:true, value:""});
            setDateState({valid:true, value:""});
            setNumberState({valid:true, value:1});
        }
    }, [selectedMeal])

    const [nameState, setNameState] = useState({valid:true, value:""});
    const setName = useNameValidation(setNameState);

    const [dateState, setDateState] = useState({valid:true, value:""});
    const setDate = useCallback((date) => {
        setDateState({valid:true, value:date});
    }, [setDateState])

    const [numberOfPeopleState, setNumberState] = useState({valid:true, value:1});
    const setNumber = useCallback((value) => {
        setNumberState({valid:true, value:value});
    }, [setNumberState])

    const value = useMemo(() => ({
        nameState,
        setName,
        dateState,
        setDate,
        numberOfPeopleState,
        setNumber
    }), [
        nameState,
        setName,
        dateState,
        setDate,
        numberOfPeopleState,
        setNumber
    ]);

    return (
        <MealFormContext.Provider value={value}>
            {children}
        </MealFormContext.Provider>
    )
}