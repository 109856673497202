import Badge from "react-bootstrap/Badge"

export function BadgeDisplay({dishTypes, diets}){
    return (
        <div className="badgeContainer">
            {dishTypes.map(label => <Badge key={label} pill bg="primary">{label}</Badge>)}
            {diets.map(label => <Badge key={label} pill bg="secondary">{label}</Badge>)}
        </div>
    )

}