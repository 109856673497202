import { axios, setAuthToken } from ".";

const meals = "/meals";

async function createMeal(fields, scheduleId){
    return axios.post(`${meals}/${scheduleId}`,fields)
        .then(data => {
            setAuthToken(data.data.token);
            return data.data.content
        });   
}

async function updateMeal(fields, mealId){
    return axios.put(`${meals}/${mealId}`, fields)
        .then(data => data.data.content)
        .catch(err => console.log(err));
}

async function deleteMeal(mealId){
    return axios.delete(`${meals}/${mealId}`);
}

export {createMeal, deleteMeal, updateMeal}