import Form from "react-bootstrap/Form"

export function DateInput({required, setDate=f=>f, valid, value, message}){

    const props = {required: required, value: value}
    
    return <Form.Group>
        <Form.Control 
            type="datetime-local" 
            onChange={(e) => setDate(e.target.value)} 
            {...props}
            isInvalid={!valid}
        />
        <Form.Control.Feedback type="invalid">
            {message}
        </Form.Control.Feedback>
    </Form.Group>
}