import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert"

import { useState, useMemo, useCallback } from "react";
import { TwoSidedDetail } from "../layouts/TwoSidedDetail";
import { HouseholdDetailsEdit } from "./HouseholdDetailsEdit";
import { UserPanelEdit } from "../user/UserPanelEdit";
import { useFormValidation } from "../../hooks/useFormValidation";
import { useHouseholdForm } from "../../contexts/HouseholdFormContext";


export function HouseholdForm({household, afterUpdate=f=>f, onCancel=f=>f}){

    const {members, nameState, setName, addMember, updateMember, createNew, updateExisting} = useHouseholdForm();

    const [validated, setValidated] = useState(false);
    const isFormValid = useFormValidation(setValidated);

    const [alert, setAlert] = useState();
    
    const onSubmit = async (e) => {
        if(!isFormValid(e, [nameState])){
            return;
        }
        if (household){
           updateExisting();
        } else {
           createNew();
        }
        setAlert();
        afterUpdate();
    }

    const updateMemberOwnership = useCallback((member) => {
        updateMember(member);
    }, [updateMember]);

    let leftSide = useMemo(() => <HouseholdDetailsEdit
        nameState={nameState}
        updateName={setName}
        onCancel={onCancel}
    />, [household, setName]);

    let rightSide = useMemo(() => <UserPanelEdit members={members} updateMember={updateMemberOwnership} addMember={addMember}/>, [members, updateMemberOwnership, addMember]);

    return (
        <Form data-cy="household-form" noValidate validated={validated} onSubmit={onSubmit}>
            {alert}
            <TwoSidedDetail 
                leftSide={leftSide}
                rightSide={rightSide}
            />
        </Form>
    )
}