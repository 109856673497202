import { useCallback, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { RecipeContext } from "../../contexts/RecipeProvider";
import { RecipeList } from "./RecipeList";
import { RecipeFilter } from "./RecipeFilter";

export function RecipeOverview({addRecipeToMeal=f=>f, onCancel=f=>f}){

    const {recipes, updateFilters, searchRecipes, autocompleteNames} = useContext(RecipeContext);

    return (<Container>
        <Row><RecipeFilter setFilters={updateFilters} searchMeals={searchRecipes} autoComplete={autocompleteNames} onCancel={onCancel}/></Row>
        <Row><RecipeList recipes={recipes} selectRecipe={addRecipeToMeal}/></Row>
    </Container>)
}