import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import { RecipeCard } from "./RecipeCard";

function RecipeList({recipes, selectRecipe=f=>f}){

    return (
        <div className="recipesList">
        {recipes.map(r => {
            return (
                <RecipeCard recipeId={r.id} selectRecipe={selectRecipe} isNew={true}/>
            );
        })}
        </div>);
}

export {RecipeList}