import { LogOut } from "../authentication/LogOut";

export function AggregatorHeader({action}){
    return (
        <>
        <div className="aggregatorHeader">
            <LogOut />
            {action}
        </div>
        <hr></hr>
        </>
    )
}